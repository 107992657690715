import React from 'react';
import './Gradient.css'

const Gradient = () => {
    return (
        <> <div className='gradient-left'>
        </div>
            <div className='gradient-right'>
            </div></>

    )
}

export default Gradient
